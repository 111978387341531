// @file Store for current user
import { getVuexStore } from '@@/bits/pinia'
import type { UserWithHashId } from '@@/types'
import type { RootState } from '@@/vuexstore/surface/types'
import { defineStore } from 'pinia'
import { computed } from 'vue'

export const useSurfaceCurrentUserStore = defineStore('surfaceCurrentUser', () => {
  const surfaceVuexStore = getVuexStore<RootState>()

  // Getters
  const currentUser = computed<UserWithHashId>(() => surfaceVuexStore?.getters.user as UserWithHashId)

  return {
    // Getters
    currentUser,
  }
})
